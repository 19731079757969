<route>
{
  "meta": {
    "auth": "checkReview"
  }
}
</route>

<template>
  <div>
    <el-card class="container">
      <el-page-header style="margin-bottom: 30px;" @back="$router.back()"> </el-page-header>
      <div class="assets">
        <div class="title">
          账户信息
          <div class="actions">
            <el-button @click="handleAction('view')">查看</el-button>
            <el-button @click="handleAction('edit')">编辑</el-button>
          </div>
        </div>
        <div class="content">
          <div class="item" v-for="item in accountInfo" :key="item.prop">
            <div class="label">{{ item.label }}：</div>

            <div class="value" v-if="item.prop === 'bankAccountType'">
              {{ data[item.prop] === '75' ? '经营者个人银行卡' : '对公银行账户' }}
            </div>
            <div class="value" v-else>{{ data[item.prop] }}</div>
          </div>
        </div>

        <div class="title" style="margin-top:40px">资金管理</div>
        <div class="money">
          <div class="item" v-for="item in moneyInfo" :key="item.prop">
            <el-tooltip effect="dark" placement="top">
              <div slot="content" v-for="i in data[item.propList]" :key="i">
                {{ i.areaId | formatArea }} : {{ i.money }}
              </div>

              <div class="label">
                {{ item.label }}
                <div class="value">
                  {{ data[item.prop] }}
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>

        <div class="confirmWithdraw">
          <div>
            <span>提现金额:</span>
            <el-input
              class="input"
              v-model.trim="data.money"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              placeholder="请输入提现金额"
              clearable
            ></el-input>
          </div>
          <div class="tip" v-if="showPreview">本次提前提现：￥{{ data.forward }}</div>
          <el-button class="confirmBtn" type="primary" :disabled="canWithdraw" @click="confirmWithdraw">提现</el-button>
          <div class="tip" v-if="data.accountNumber === ''">请为该二级商户号绑定银行卡</div>
        </div>
      </div>
      <div class="take-table">
        <div class="header">
          <div class="label">起止时间</div>
          <el-date-picker
            v-model="filter.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <div class="label" style="margin-left:30px">提现订单</div>
          <el-input style="width:280px" v-model="filter.order"></el-input>
          <div class="btns">
            <el-button type="warning" @click="resetSearch">重置</el-button>
            <el-button type="success" @click="getList">搜索</el-button>
          </div>
        </div>
        <el-table :loading="loading" :data="table.list">
          <el-table-column v-for="item in table.cols" :key="item.label" :prop="item.prop" :label="item.label">
            <template slot-scope="{ row }">
              <div class="" v-if="item.type === 'time'">{{ row[item.prop] | formatTime }}</div>
              <div v-else-if="item.prop === 'applyWithdrawStatus'">
                {{ table.applyWithdrawStatus[row[item.prop]] }}
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            class="pagination"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      @close="confirmDialog = false"
      :visible="confirmDialog"
      title="滑动确认提现"
      class="assets"
      width="400px"
    >
      <bm-dragverify
        :key="id"
        :width="350"
        :height="40"
        text="按住滑块向右拖动确认"
        @passcallback="finish"
        success-text="系统提示:正在处理中"
      ></bm-dragverify>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialog.show"
      @close="dialog.show = false"
      :title="dialog.type === 'view' ? '查看' : dialog.type === 'add' ? '添加' : '编辑'"
    >
      <addDialog :id="dialog.subMerchantId" :show="dialog.show" :type="dialog.type" @success="getDetail" />
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import addDialog from './__com__/addDialog'
export default {
  mixins: [pagination],
  components: { addDialog },
  data() {
    return {
      accountInfo: [
        {
          label: '账号名称',
          prop: 'accountName'
        },
        // {
        //   label: '业务申请编号',
        //   prop: 'accountNumber'
        // },
        // {
        //   label: '审核状态',
        //   prop: ''
        // },
        {
          label: '开户银行',
          prop: 'accountBank'
        },
        {
          label: '银行账号',
          prop: 'accountNumber'
        },
        {
          label: '对账类型',
          prop: 'bankAccountType'
        }
      ],
      moneyInfo: [
        {
          label: '总金额（元）',
          prop: 'totalAccountMoney',
          propList: 'totalAccountMoneyList'
        },
        {
          label: '已提前提现金额',
          prop: 'aheadWithdrawMoney',
          propList: 'aheadWithdrawMoneyList'
        },
        {
          label: '平台全收未核销金额（元）',
          prop: 'unconfirmedMoney',
          propList: 'unconfirmedMoneyList'
        },
        {
          label: '已核销（元）',
          prop: 'canWithdrawMoney',
          propList: 'canWithdrawMoneyList'
        },
        {
          label: '待分成金额（元）',
          prop: 'frozenMoney',
          propList: 'frozenMoneyList'
        },
        {
          label: '已提现金额（元）',
          prop: 'haveWithdrawMoney',
          propList: 'haveWithdrawMoneyList'
        }
      ],
      data: {
        forward: '',
        can: false,
        money: '',
        aheadWithdrawMoney: 0.2,
        bankAccountType: '75',
        canWithdrawMoney: 0,
        frozenMoney: 5.94,
        haveWithdrawMoney: 17.39,
        tenantId: 0,
        totalAccountMoney: 7.46,
        unconfirmedMoney: 1.52
      },
      confirmDialog: false,
      id: new Date().toString(),
      filter: {
        date: [],
        order: ''
      },
      loading: false,
      table: {
        list: [],
        cols: [
          {
            label: '提现订单号',
            prop: 'id'
          },
          {
            label: '提现时间',
            prop: 'applyTime',
            type: 'time'
          },
          {
            label: '操作人',
            prop: 'operatorMemberName'
          },
          {
            label: '提现账号',
            prop: 'accountNumber'
          },
          {
            label: '到账时间',
            prop: 'collectedTime',
            type: 'time'
          },
          {
            label: '提现金额',
            prop: 'amount'
          },
          {
            label: '状态',
            prop: 'applyWithdrawStatus'
          },
          {
            label: '备注',
            prop: 'remark'
          }
        ],
        applyWithdrawStatus: {
          0: '发起提现',
          1: '调用接口失败',
          2: '提现成功',
          3: '提现失败'
        }
      },
      dialog: {
        show: false,
        type: 'view',
        subMerchantId: 0
      }
    }
  },
  created() {
    this.dialog.subMerchantId = this.$route.query.subMerchantId
    this.getDetail()
  },
  computed: {
    showPreview() {
      if (this.data.money > this.data.canWithdrawMoney) {
        this.data.forward = (this.data.money - this.data.canWithdrawMoney).toFixed(2)
        return true
      } else {
        return false
      }
    },
    canWithdraw() {
      if (this.data.accountNumber === '' || this.data.money === '') {
        return true
      }
      return false
    }
  },
  methods: {
    finish() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .post('boom-mix-biz-service/sysAdmin/platformAccount/withdraw', {
          bankMemo: '',
          remark: '',
          subMchid: this.$route.query.id,
          money: this.data.money
        })
        .then(() => {
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          this.getDetail()
          this.data.money = ''
          loading.close()
          this.id = new Date().toString()
          this.confirmDialog = false
        })
    },
    confirmWithdraw() {
      if (this.data.money > this.data.unconfirmedMoney + this.data.canWithdrawMoney) {
        this.$message.error('提现金额大于已核销金额和全收未提现金额')
        return
      }
      this.confirmDialog = true
    },
    getDetail() {
      this.$http
        .get('boom-mix-biz-service/sysAdmin/platformAccount/account/' + this.$route.query.id)
        .then(res => {
          this.data = { ...this.data, ...res }
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    resetSearch() {
      this.filter = {
        date: [],
        order: ''
      }
      this.getList()
    },
    getList(page = 1) {
      this.loading = true
      this.currentPage = page === true ? this.currentPage : 1
      let params = {
        page: this.currentPage,
        subMchid: this.$route.query.id
      }
      if (this.filter.order) {
        params.id = this.filter.order
      }
      if (this.filter.date.length) {
        params.applyStartTime = new Date(this.filter.date[0]).getTime()
        params.applyEndTime = new Date(this.filter.date[1]).getTime()
      }
      let paramStr = ''
      Object.keys(params).map(key => (paramStr += `${key}=${params[key]}&`))
      this.$http
        .get('boom-center-order-service/sysAdmin/withdraw?' + paramStr)
        .then(res => {
          console.log(res)
          this.total = parseInt(res.total)
          this.table.list = res.list
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => (this.loading = false))
    },
    handleAction(type = 'view') {
      this.dialog.type = type
      this.dialog.show = true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: calc(100vh - 50px);
  background: #f9f9f9;
}
.assets {
  background: #fff;
  padding: 20px;
  margin-bottom: 30px;
  .title {
    font-size: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    display: flex;
    .item {
      flex-basis: 50%;
      display: flex;
      font-size: 15px;
      overflow: hidden;
      padding-right: 15px;
      .label {
        color: #999;
      }
      .value {
        flex: 1;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  .money {
    display: flex;
    background: #f7f7f7;
    padding: 30px;
    .item {
      flex: 1;
      word-break: break-all;
      text-align: center;
      .label {
        color: #999;
        font-size: 15px;
        margin-bottom: 5px;
      }
      .value {
        color: #e7141a;
        font-weight: bold;
        font-size: 26px;
      }
    }
  }
  .accountItem {
    margin: 16px 0;
    font-size: 18px;
    .intro {
      display: inline-block;
      width: 200px;
    }
    span {
      font-size: 16px;
      font-weight: 100;
    }
  }

  .confirmWithdraw {
    display: flex;
    align-items: center;
    margin-top: 15px;
    span {
      font-size: 15px;
      margin-right: 10px;
    }
    .input {
      width: 250px;
    }
    .confirmBtn {
      width: 100px;
      margin: 0 15px;
    }
    .tip {
      // text-align: center;
      color: red;
      font-size: 12px;
    }
  }
}
.take-table {
  margin-top: 30px;
  background: #fff;
  padding: 20px;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .label {
      font-size: 15px;
      margin-right: 10px;
    }
    .btns {
      flex: 1;
      text-align: right;
    }
  }
  .footer {
    text-align: right;
    margin-top: 15px;
  }
}
</style>
